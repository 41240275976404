define("@yogeshsahu/fleetops-data/utils/geojson/create-primitive", ["exports", "@yogeshsahu/fleetops-data/utils/geojson/point", "@yogeshsahu/fleetops-data/utils/geojson/multi-point", "@yogeshsahu/fleetops-data/utils/geojson/line-string", "@yogeshsahu/fleetops-data/utils/geojson/multi-line-string", "@yogeshsahu/fleetops-data/utils/geojson/polygon", "@yogeshsahu/fleetops-data/utils/geojson/multi-polygon", "@yogeshsahu/fleetops-data/utils/geojson/feature", "@yogeshsahu/fleetops-data/utils/geojson/feature-collection", "@yogeshsahu/fleetops-data/utils/geojson/geometry-collection", "@yogeshsahu/fleetops-data/utils/geojson/circle"], function (_exports, _point, _multiPoint, _lineString, _multiLineString, _polygon, _multiPolygon, _feature, _featureCollection, _geometryCollection, _circle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = geojsonCreatePrimitive;
  function geojsonCreatePrimitive(geojson) {
    if (geojson) {
      switch (geojson.type) {
        case 'Point':
          return new _point.default(geojson);
        case 'MultiPoint':
          return new _multiPoint.default(geojson);
        case 'LineString':
          return new _lineString.default(geojson);
        case 'MultiLineString':
          return new _multiLineString.default(geojson);
        case 'Polygon':
          return new _polygon.default(geojson);
        case 'MultiPolygon':
          return new _multiPolygon.default(geojson);
        case 'Feature':
          return new _feature.default(geojson);
        case 'FeatureCollection':
          return new _featureCollection.default(geojson);
        case 'GeometryCollection':
          return new _geometryCollection.default(geojson);
        case 'Circle':
          return new _circle.default(geojson);
        default:
          throw new Error('Unknown type: ' + geojson.type);
      }
    }
  }
});