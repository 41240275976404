define("@yogeshsahu/fleetops-data/utils/geojson/geometry-collection", ["exports", "@yogeshsahu/fleetops-data/utils/geojson/geo-json", "@ember/array"], function (_exports, _geoJson, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class GeometryCollection extends _geoJson.default {
    constructor(input) {
      super();
      if (input && input.type === 'GeometryCollection' && input.geometries) {
        Object.assign(this, input);
      } else if ((0, _array.isArray)(input)) {
        this.geometries = input;
      } else if (input.coordinates && input.type) {
        this.type = 'GeometryCollection';
        this.geometries = [input];
      } else {
        throw 'GeoJSON: invalid input for new GeometryCollection';
      }
      this.type = 'GeometryCollection';
    }
    forEach(func) {
      for (var i = 0; i < this.geometries.length; i++) {
        func.apply(this, [this.geometries[i], i, this.geometries]);
      }
    }
    get(i) {
      return new _geoJson.default(this.geometries[i]);
    }
  }
  _exports.default = GeometryCollection;
});