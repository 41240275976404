define("@yogeshsahu/fleetops-data/serializers/zone", ["exports", "@fleetbase/ember-core/serializers/application", "@ember-data/serializer/rest"], function (_exports, _application, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ZoneSerializer extends _application.default.extend(_rest.EmbeddedRecordsMixin) {
    /**
     * Embedded relationship attributes
     *
     * @var {Object}
     */
    get attrs() {
      return {
        service_area: {
          serialize: 'ids'
        }
      };
    }
  }
  _exports.default = ZoneSerializer;
});