define("@yogeshsahu/fleetops-data/utils/geojson/multi-line-string", ["exports", "@yogeshsahu/fleetops-data/utils/geojson/geo-json", "@yogeshsahu/fleetops-data/utils/geojson/line-string", "@ember/array"], function (_exports, _geoJson, _lineString, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class MultiLineString extends _geoJson.default {
    constructor(input) {
      super();
      if (input && input.type === 'MultiLineString' && input.coordinates) {
        Object.assign(this, input);
      } else if ((0, _array.isArray)(input)) {
        this.coordinates = input;
      } else {
        throw 'GeoJSON: invalid input for new MultiLineString';
      }
      this.type = 'MultiLineString';
    }
    forEach(func) {
      for (var i = 0; i < this.coordinates.length; i++) {
        func.apply(this, [this.coordinates[i], i, this.coordinates]);
      }
    }
    get(i) {
      return new _lineString.default(this.coordinates[i]);
    }
  }
  _exports.default = MultiLineString;
});