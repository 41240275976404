define("@yogeshsahu/fleetops-data/utils/geojson/feature", ["exports", "@yogeshsahu/fleetops-data/utils/geojson/geo-json"], function (_exports, _geoJson) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Feature extends _geoJson.default {
    constructor(input) {
      super();
      if (input && input.type === 'Feature') {
        Object.assign(this, input);
      } else if (input && input.type && input.coordinates) {
        this.geometry = input;
      } else {
        throw 'GeoJSON: invalid input for new Feature';
      }
      this.type = 'Feature';
    }
  }
  _exports.default = Feature;
});